.bidsBoard {
  margin-bottom: 1rem;
}

.auction {
  margin-bottom: 1rem;
  position: relative;
  margin-right: 3%;
}

.issueRow1,
.issueRow2,
.issueRow3,
.issueRow4 {
  position: absolute;
  width: 23.5%;
  height: 5.5%;
}

.issueRow1 {
  top: 25%;
  left: 74%;
}

.issueRow2 {
  top: 33%;
  left: 73.5%;
}

.issueRow3 {
  top: 42%;
  left: 73%;
}

.issueRow4 {
  top: 51%;
  left: 72.5%;
}

.issueRow1 > div,
.issueRow2 > div,
.issueRow3 > div,
.issueRow4 > div {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.issueDiv {
  color: black;
  width: 13.65%;
  height: 100%;
  background: #dfe9ff;
  font-size: min(1vw, 12px);
  text-align: center;
  border: 1px dotted;
  margin-right: 6.3%;
  padding: 3% 1%;
  cursor: pointer;
}

.issueDiv p {
  /* margin: 10% 5%; */
  margin: 0;
  font-weight: 800;
  font-family: bernierRegular;
  line-height: 1.5vh;
}

.issueNum_mobile {
  display: none;
}

.auctionImage,
.bidsBoardImage {
  width: 100%;
}

.nftImage {
  position: absolute;
  top: 2.08%;
  left: 36.83%;
  width: 25%;
  animation: loop 7s linear infinite;
}

.bidBtn {
  position: absolute;
  top: 76%;
  left: 41.8%;
  width: 21%;
}
.bidBtn:hover {
  content: url('../../assets/auction/bid_pressed.png');
}

.claimBtn {
  position: absolute;
  top: 85%;
  left: 41.8%;
  width: 21%;
}

.claimBtn:hover {
  content: url('../../assets/auction/claim_pressed.png');
}

.auctionInput {
  position: absolute;
  top: 69.6%;
  left: 46.4%;
  width: 15%;
  height: 4%;

  border: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  font-family: pFont;
  font-size: 1rem;
  text-align: center;
}

input:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.issues_mobile {
  display: none;
}

.bidsBoard > div {
  width: 100%;
  background-color: #dfe9ff;
}

.bidsBoard table {
  width: 94%;
  border-collapse: separate;
  border-spacing: 0 min(0.5vh, 5px);
  margin-left: auto;
  margin-right: auto;
}

.bidsBoard th {
  font-size: min(4vw, 40px);
  background-color: #a6afbf;
  font-family: headersFont;
  width: 20%;
  text-align: center;
  border: 3px solid black;
  font-weight: 400;
  border-radius: 5px;
}

.bidsBoard tr {
  background-color: #dfe9ff;
  width: 100%;
}

.bidsBoard td {
  padding: 0px 7px 0px 7px;
  white-space: nowrap;
  font-size: min(2.5vw, 25px);
  font-family: pFont;
  text-align: center;
}

.bidsBoard td img {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .issues_mobile {
    display: block;
    position: absolute;
    right: 12.3%;
    top: 22%;
    font-size: 2vw;
    font-family: bernierRegular;
  }

  .issueDiv p {
    display: none;
  }
  .issueNum_mobile {
    display: block !important;
    margin: 10% 5%;
    font-weight: 800;
    font-size: 1.7vw;
  }
}

@keyframes loop {
  0% {
    top: 2.08%;
    left: 36.83%;
  }

  0.33% {
    top: 2.08%;
    left: 36.83%;
  }

  0.66% {
    top: 2.08%;
    left: 36.94%;
  }

  0.99% {
    top: 2.09%;
    left: 37.05%;
  }

  1.32% {
    top: 2.11%;
    left: 37.15%;
  }

  1.64% {
    top: 2.14%;
    left: 37.26%;
  }

  1.97% {
    top: 2.17%;
    left: 37.36%;
  }

  2.3% {
    top: 2.2%;
    left: 37.45%;
  }

  2.63% {
    top: 2.24%;
    left: 37.55%;
  }

  2.96% {
    top: 2.28%;
    left: 37.64%;
  }

  3.29% {
    top: 2.33%;
    left: 37.73%;
  }

  3.62% {
    top: 2.38%;
    left: 37.82%;
  }

  3.95% {
    top: 2.43%;
    left: 37.9%;
  }

  4.28% {
    top: 2.48%;
    left: 37.98%;
  }

  4.61% {
    top: 2.53%;
    left: 38.06%;
  }

  4.93% {
    top: 2.59%;
    left: 38.14%;
  }

  5.26% {
    top: 2.64%;
    left: 38.22%;
  }

  5.59% {
    top: 2.7%;
    left: 38.29%;
  }

  5.92% {
    top: 2.76%;
    left: 38.37%;
  }

  6.25% {
    top: 2.82%;
    left: 38.44%;
  }

  6.58% {
    top: 2.88%;
    left: 38.51%;
  }

  6.91% {
    top: 2.94%;
    left: 38.57%;
  }

  7.24% {
    top: 3%;
    left: 38.64%;
  }

  7.57% {
    top: 3.06%;
    left: 38.71%;
  }

  7.89% {
    top: 3.13%;
    left: 38.77%;
  }

  8.22% {
    top: 3.19%;
    left: 38.84%;
  }

  8.55% {
    top: 3.25%;
    left: 38.9%;
  }

  8.88% {
    top: 3.32%;
    left: 38.96%;
  }

  9.21% {
    top: 3.38%;
    left: 39.02%;
  }

  9.54% {
    top: 3.45%;
    left: 39.08%;
  }

  9.87% {
    top: 3.52%;
    left: 39.14%;
  }

  10.2% {
    top: 3.58%;
    left: 39.2%;
  }

  10.53% {
    top: 3.65%;
    left: 39.25%;
  }

  10.86% {
    top: 3.72%;
    left: 39.31%;
  }

  11.18% {
    top: 3.78%;
    left: 39.36%;
  }

  11.51% {
    top: 3.85%;
    left: 39.42%;
  }

  11.84% {
    top: 3.92%;
    left: 39.47%;
  }

  12.17% {
    top: 3.99%;
    left: 39.52%;
  }

  12.5% {
    top: 4.06%;
    left: 39.57%;
  }

  12.83% {
    top: 4.13%;
    left: 39.62%;
  }

  13.16% {
    top: 4.2%;
    left: 39.67%;
  }

  13.49% {
    top: 4.27%;
    left: 39.73%;
  }

  13.82% {
    top: 4.33%;
    left: 39.78%;
  }

  14.14% {
    top: 4.4%;
    left: 39.84%;
  }

  14.47% {
    top: 4.47%;
    left: 39.89%;
  }

  14.8% {
    top: 4.54%;
    left: 39.95%;
  }

  15.13% {
    top: 4.6%;
    left: 40.01%;
  }

  15.46% {
    top: 4.67%;
    left: 40.07%;
  }

  15.79% {
    top: 4.73%;
    left: 40.13%;
  }

  16.12% {
    top: 4.8%;
    left: 40.19%;
  }

  16.45% {
    top: 4.86%;
    left: 40.25%;
  }

  16.78% {
    top: 4.93%;
    left: 40.31%;
  }

  17.11% {
    top: 4.99%;
    left: 40.38%;
  }

  17.43% {
    top: 5.05%;
    left: 40.44%;
  }

  17.76% {
    top: 5.12%;
    left: 40.51%;
  }

  18.09% {
    top: 5.18%;
    left: 40.58%;
  }

  18.42% {
    top: 5.24%;
    left: 40.64%;
  }

  18.75% {
    top: 5.3%;
    left: 40.72%;
  }

  19.08% {
    top: 5.36%;
    left: 40.79%;
  }

  19.41% {
    top: 5.41%;
    left: 40.86%;
  }

  19.74% {
    top: 5.47%;
    left: 40.94%;
  }

  20.07% {
    top: 5.53%;
    left: 41.01%;
  }

  20.39% {
    top: 5.58%;
    left: 41.09%;
  }

  20.72% {
    top: 5.63%;
    left: 41.17%;
  }

  21.05% {
    top: 5.68%;
    left: 41.26%;
  }

  21.38% {
    top: 5.73%;
    left: 41.34%;
  }

  21.71% {
    top: 5.78%;
    left: 41.43%;
  }

  22.04% {
    top: 5.82%;
    left: 41.52%;
  }

  22.37% {
    top: 5.86%;
    left: 41.62%;
  }

  22.7% {
    top: 5.9%;
    left: 41.71%;
  }

  23.03% {
    top: 5.93%;
    left: 41.81%;
  }

  23.36% {
    top: 5.96%;
    left: 41.91%;
  }

  23.68% {
    top: 5.98%;
    left: 42.02%;
  }

  24.01% {
    top: 5.99%;
    left: 42.13%;
  }

  24.34% {
    top: 6%;
    left: 42.23%;
  }

  24.67% {
    top: 6%;
    left: 42.34%;
  }

  25% {
    top: 5.99%;
    left: 42.45%;
  }

  25.33% {
    top: 5.99%;
    left: 42.56%;
  }

  25.66% {
    top: 5.97%;
    left: 42.67%;
  }

  25.99% {
    top: 5.96%;
    left: 42.77%;
  }

  26.32% {
    top: 5.94%;
    left: 42.88%;
  }

  26.64% {
    top: 5.92%;
    left: 42.98%;
  }

  26.97% {
    top: 5.89%;
    left: 43.09%;
  }

  27.3% {
    top: 5.86%;
    left: 43.19%;
  }

  27.63% {
    top: 5.83%;
    left: 43.29%;
  }

  27.96% {
    top: 5.79%;
    left: 43.38%;
  }

  28.29% {
    top: 5.76%;
    left: 43.48%;
  }

  28.62% {
    top: 5.72%;
    left: 43.57%;
  }

  28.95% {
    top: 5.67%;
    left: 43.67%;
  }

  29.28% {
    top: 5.63%;
    left: 43.76%;
  }

  29.61% {
    top: 5.58%;
    left: 43.84%;
  }

  29.93% {
    top: 5.53%;
    left: 43.93%;
  }

  30.26% {
    top: 5.48%;
    left: 44.01%;
  }

  30.59% {
    top: 5.43%;
    left: 44.09%;
  }

  30.92% {
    top: 5.37%;
    left: 44.17%;
  }

  31.25% {
    top: 5.32%;
    left: 44.24%;
  }

  31.58% {
    top: 5.26%;
    left: 44.31%;
  }

  31.91% {
    top: 5.2%;
    left: 44.38%;
  }

  32.24% {
    top: 5.14%;
    left: 44.45%;
  }

  32.57% {
    top: 5.07%;
    left: 44.52%;
  }

  32.89% {
    top: 5.01%;
    left: 44.58%;
  }

  33.22% {
    top: 4.94%;
    left: 44.63%;
  }

  33.55% {
    top: 4.87%;
    left: 44.69%;
  }

  33.88% {
    top: 4.8%;
    left: 44.74%;
  }

  34.21% {
    top: 4.73%;
    left: 44.78%;
  }

  34.54% {
    top: 4.66%;
    left: 44.83%;
  }

  34.87% {
    top: 4.59%;
    left: 44.86%;
  }

  35.2% {
    top: 4.51%;
    left: 44.9%;
  }

  35.53% {
    top: 4.44%;
    left: 44.93%;
  }

  35.86% {
    top: 4.36%;
    left: 44.95%;
  }

  36.18% {
    top: 4.28%;
    left: 44.97%;
  }

  36.51% {
    top: 4.21%;
    left: 44.99%;
  }

  36.84% {
    top: 4.13%;
    left: 45%;
  }

  37.17% {
    top: 4.05%;
    left: 45%;
  }

  37.5% {
    top: 3.97%;
    left: 45%;
  }

  37.83% {
    top: 3.89%;
    left: 44.99%;
  }

  38.16% {
    top: 3.81%;
    left: 44.98%;
  }

  38.49% {
    top: 3.74%;
    left: 44.96%;
  }

  38.82% {
    top: 3.66%;
    left: 44.93%;
  }

  39.14% {
    top: 3.58%;
    left: 44.91%;
  }

  39.47% {
    top: 3.51%;
    left: 44.87%;
  }

  39.8% {
    top: 3.44%;
    left: 44.84%;
  }

  40.13% {
    top: 3.36%;
    left: 44.79%;
  }

  40.46% {
    top: 3.29%;
    left: 44.75%;
  }

  40.79% {
    top: 3.22%;
    left: 44.7%;
  }

  41.12% {
    top: 3.15%;
    left: 44.65%;
  }

  41.45% {
    top: 3.09%;
    left: 44.59%;
  }

  41.78% {
    top: 3.02%;
    left: 44.53%;
  }

  42.11% {
    top: 2.96%;
    left: 44.47%;
  }

  42.43% {
    top: 2.89%;
    left: 44.4%;
  }

  42.76% {
    top: 2.83%;
    left: 44.33%;
  }

  43.09% {
    top: 2.78%;
    left: 44.26%;
  }

  43.42% {
    top: 2.72%;
    left: 44.19%;
  }

  43.75% {
    top: 2.66%;
    left: 44.11%;
  }

  44.08% {
    top: 2.61%;
    left: 44.03%;
  }

  44.41% {
    top: 2.56%;
    left: 43.95%;
  }

  44.74% {
    top: 2.51%;
    left: 43.86%;
  }

  45.07% {
    top: 2.46%;
    left: 43.78%;
  }

  45.39% {
    top: 2.42%;
    left: 43.69%;
  }

  45.72% {
    top: 2.37%;
    left: 43.6%;
  }

  46.05% {
    top: 2.33%;
    left: 43.5%;
  }

  46.38% {
    top: 2.29%;
    left: 43.41%;
  }

  46.71% {
    top: 2.26%;
    left: 43.31%;
  }

  47.04% {
    top: 2.23%;
    left: 43.21%;
  }

  47.37% {
    top: 2.2%;
    left: 43.11%;
  }

  47.7% {
    top: 2.17%;
    left: 43.01%;
  }

  48.03% {
    top: 2.15%;
    left: 42.9%;
  }

  48.36% {
    top: 2.12%;
    left: 42.8%;
  }

  48.68% {
    top: 2.11%;
    left: 42.69%;
  }

  49.01% {
    top: 2.09%;
    left: 42.59%;
  }

  49.34% {
    top: 2.09%;
    left: 42.48%;
  }

  49.67% {
    top: 2.08%;
    left: 42.37%;
  }

  50% {
    top: 2.08%;
    left: 42.26%;
  }

  50.33% {
    top: 2.08%;
    left: 42.15%;
  }

  50.66% {
    top: 2.1%;
    left: 42.04%;
  }

  50.99% {
    top: 2.12%;
    left: 41.94%;
  }

  51.32% {
    top: 2.14%;
    left: 41.84%;
  }

  51.64% {
    top: 2.17%;
    left: 41.74%;
  }

  51.97% {
    top: 2.21%;
    left: 41.64%;
  }

  52.3% {
    top: 2.25%;
    left: 41.54%;
  }

  52.63% {
    top: 2.29%;
    left: 41.45%;
  }

  52.96% {
    top: 2.34%;
    left: 41.36%;
  }

  53.29% {
    top: 2.38%;
    left: 41.28%;
  }

  53.62% {
    top: 2.43%;
    left: 41.19%;
  }

  53.95% {
    top: 2.49%;
    left: 41.11%;
  }

  54.28% {
    top: 2.54%;
    left: 41.03%;
  }

  54.61% {
    top: 2.59%;
    left: 40.96%;
  }

  54.93% {
    top: 2.65%;
    left: 40.88%;
  }

  55.26% {
    top: 2.71%;
    left: 40.8%;
  }

  55.59% {
    top: 2.77%;
    left: 40.73%;
  }

  55.92% {
    top: 2.83%;
    left: 40.66%;
  }

  56.25% {
    top: 2.89%;
    left: 40.59%;
  }

  56.58% {
    top: 2.95%;
    left: 40.52%;
  }

  56.91% {
    top: 3.01%;
    left: 40.46%;
  }

  57.24% {
    top: 3.07%;
    left: 40.39%;
  }

  57.57% {
    top: 3.14%;
    left: 40.33%;
  }

  57.89% {
    top: 3.2%;
    left: 40.26%;
  }

  58.22% {
    top: 3.26%;
    left: 40.2%;
  }

  58.55% {
    top: 3.33%;
    left: 40.14%;
  }

  58.88% {
    top: 3.39%;
    left: 40.08%;
  }

  59.21% {
    top: 3.46%;
    left: 40.02%;
  }

  59.54% {
    top: 3.53%;
    left: 39.96%;
  }

  59.87% {
    top: 3.59%;
    left: 39.91%;
  }

  60.2% {
    top: 3.66%;
    left: 39.85%;
  }

  60.53% {
    top: 3.73%;
    left: 39.79%;
  }

  60.86% {
    top: 3.8%;
    left: 39.74%;
  }

  61.18% {
    top: 3.86%;
    left: 39.69%;
  }

  61.51% {
    top: 3.93%;
    left: 39.63%;
  }

  61.84% {
    top: 4%;
    left: 39.58%;
  }

  62.17% {
    top: 4.07%;
    left: 39.53%;
  }

  62.5% {
    top: 4.14%;
    left: 39.48%;
  }

  62.83% {
    top: 4.21%;
    left: 39.43%;
  }

  63.16% {
    top: 4.28%;
    left: 39.37%;
  }

  63.49% {
    top: 4.35%;
    left: 39.32%;
  }

  63.82% {
    top: 4.41%;
    left: 39.26%;
  }

  64.14% {
    top: 4.48%;
    left: 39.21%;
  }

  64.47% {
    top: 4.55%;
    left: 39.15%;
  }

  64.8% {
    top: 4.61%;
    left: 39.09%;
  }

  65.13% {
    top: 4.68%;
    left: 39.03%;
  }

  65.46% {
    top: 4.74%;
    left: 38.97%;
  }

  65.79% {
    top: 4.81%;
    left: 38.91%;
  }

  66.12% {
    top: 4.87%;
    left: 38.85%;
  }

  66.45% {
    top: 4.94%;
    left: 38.79%;
  }

  66.78% {
    top: 5%;
    left: 38.72%;
  }

  67.11% {
    top: 5.06%;
    left: 38.66%;
  }

  67.43% {
    top: 5.13%;
    left: 38.59%;
  }

  67.76% {
    top: 5.19%;
    left: 38.52%;
  }

  68.09% {
    top: 5.25%;
    left: 38.45%;
  }

  68.42% {
    top: 5.31%;
    left: 38.38%;
  }

  68.75% {
    top: 5.37%;
    left: 38.31%;
  }

  69.08% {
    top: 5.42%;
    left: 38.24%;
  }

  69.41% {
    top: 5.48%;
    left: 38.16%;
  }

  69.74% {
    top: 5.53%;
    left: 38.08%;
  }

  70.07% {
    top: 5.59%;
    left: 38%;
  }

  70.39% {
    top: 5.64%;
    left: 37.92%;
  }

  70.72% {
    top: 5.69%;
    left: 37.84%;
  }

  71.05% {
    top: 5.74%;
    left: 37.75%;
  }

  71.38% {
    top: 5.78%;
    left: 37.66%;
  }

  71.71% {
    top: 5.83%;
    left: 37.57%;
  }

  72.04% {
    top: 5.87%;
    left: 37.48%;
  }

  72.37% {
    top: 5.9%;
    left: 37.38%;
  }

  72.7% {
    top: 5.93%;
    left: 37.28%;
  }

  73.03% {
    top: 5.96%;
    left: 37.18%;
  }

  73.36% {
    top: 5.98%;
    left: 37.07%;
  }

  73.68% {
    top: 5.99%;
    left: 36.97%;
  }

  74.01% {
    top: 6%;
    left: 36.86%;
  }

  74.34% {
    top: 6%;
    left: 36.75%;
  }

  74.67% {
    top: 5.99%;
    left: 36.64%;
  }

  75% {
    top: 5.98%;
    left: 36.53%;
  }

  75.33% {
    top: 5.97%;
    left: 36.42%;
  }

  75.66% {
    top: 5.96%;
    left: 36.32%;
  }

  75.99% {
    top: 5.93%;
    left: 36.21%;
  }

  76.32% {
    top: 5.91%;
    left: 36.11%;
  }

  76.64% {
    top: 5.88%;
    left: 36.01%;
  }

  76.97% {
    top: 5.86%;
    left: 35.91%;
  }

  77.3% {
    top: 5.82%;
    left: 35.81%;
  }

  77.63% {
    top: 5.79%;
    left: 35.71%;
  }

  77.96% {
    top: 5.75%;
    left: 35.61%;
  }

  78.29% {
    top: 5.71%;
    left: 35.52%;
  }

  78.62% {
    top: 5.67%;
    left: 35.43%;
  }

  78.95% {
    top: 5.62%;
    left: 35.34%;
  }

  79.28% {
    top: 5.57%;
    left: 35.25%;
  }

  79.61% {
    top: 5.52%;
    left: 35.17%;
  }

  79.93% {
    top: 5.47%;
    left: 35.09%;
  }

  80.26% {
    top: 5.42%;
    left: 35.01%;
  }

  80.59% {
    top: 5.36%;
    left: 34.93%;
  }

  80.92% {
    top: 5.31%;
    left: 34.85%;
  }

  81.25% {
    top: 5.25%;
    left: 34.78%;
  }

  81.58% {
    top: 5.19%;
    left: 34.71%;
  }

  81.91% {
    top: 5.13%;
    left: 34.65%;
  }

  82.24% {
    top: 5.06%;
    left: 34.58%;
  }

  82.57% {
    top: 5%;
    left: 34.52%;
  }

  82.89% {
    top: 4.93%;
    left: 34.47%;
  }

  83.22% {
    top: 4.86%;
    left: 34.41%;
  }

  83.55% {
    top: 4.79%;
    left: 34.36%;
  }

  83.88% {
    top: 4.72%;
    left: 34.32%;
  }

  84.21% {
    top: 4.65%;
    left: 34.28%;
  }

  84.54% {
    top: 4.57%;
    left: 34.24%;
  }

  84.87% {
    top: 4.5%;
    left: 34.21%;
  }

  85.2% {
    top: 4.42%;
    left: 34.18%;
  }

  85.53% {
    top: 4.35%;
    left: 34.15%;
  }

  85.86% {
    top: 4.27%;
    left: 34.13%;
  }

  86.18% {
    top: 4.19%;
    left: 34.12%;
  }

  86.51% {
    top: 4.11%;
    left: 34.11%;
  }

  86.84% {
    top: 4.04%;
    left: 34.11%;
  }

  87.17% {
    top: 3.96%;
    left: 34.11%;
  }

  87.5% {
    top: 3.88%;
    left: 34.12%;
  }

  87.83% {
    top: 3.8%;
    left: 34.14%;
  }

  88.16% {
    top: 3.72%;
    left: 34.15%;
  }

  88.49% {
    top: 3.65%;
    left: 34.18%;
  }

  88.82% {
    top: 3.57%;
    left: 34.21%;
  }

  89.14% {
    top: 3.5%;
    left: 34.24%;
  }

  89.47% {
    top: 3.43%;
    left: 34.28%;
  }

  89.8% {
    top: 3.35%;
    left: 34.32%;
  }

  90.13% {
    top: 3.28%;
    left: 34.37%;
  }

  90.46% {
    top: 3.21%;
    left: 34.42%;
  }

  90.79% {
    top: 3.14%;
    left: 34.47%;
  }

  91.12% {
    top: 3.08%;
    left: 34.53%;
  }

  91.45% {
    top: 3.01%;
    left: 34.59%;
  }

  91.78% {
    top: 2.95%;
    left: 34.65%;
  }

  92.11% {
    top: 2.89%;
    left: 34.72%;
  }

  92.43% {
    top: 2.83%;
    left: 34.79%;
  }

  92.76% {
    top: 2.77%;
    left: 34.86%;
  }

  93.09% {
    top: 2.71%;
    left: 34.94%;
  }

  93.42% {
    top: 2.65%;
    left: 35.01%;
  }

  93.75% {
    top: 2.6%;
    left: 35.09%;
  }

  94.08% {
    top: 2.55%;
    left: 35.18%;
  }

  94.41% {
    top: 2.5%;
    left: 35.26%;
  }

  94.74% {
    top: 2.45%;
    left: 35.35%;
  }

  95.07% {
    top: 2.41%;
    left: 35.44%;
  }

  95.39% {
    top: 2.37%;
    left: 35.53%;
  }

  95.72% {
    top: 2.33%;
    left: 35.62%;
  }

  96.05% {
    top: 2.29%;
    left: 35.72%;
  }

  96.38% {
    top: 2.25%;
    left: 35.81%;
  }

  96.71% {
    top: 2.22%;
    left: 35.91%;
  }

  97.04% {
    top: 2.19%;
    left: 36.01%;
  }

  97.37% {
    top: 2.16%;
    left: 36.12%;
  }

  97.7% {
    top: 2.14%;
    left: 36.22%;
  }

  98.03% {
    top: 2.12%;
    left: 36.33%;
  }

  98.36% {
    top: 2.11%;
    left: 36.43%;
  }

  98.68% {
    top: 2.09%;
    left: 36.54%;
  }

  99.01% {
    top: 2.08%;
    left: 36.65%;
  }

  99.34% {
    top: 2.08%;
    left: 36.76%;
  }
}
