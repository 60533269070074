.roomSection {
  overflow: hidden;
  background-image: url('../../assets/callerdao/dao_full-view_no-tv.png');
  --bg-size: calc(100vh * (3080 / 1080));
  background-size: calc(1.1 * var(--bg-size));
  background-position-x: 80%;
  background-position-y: 20%;
  height: 100vh;
  position: relative;
  transition: all 1s ease-in-out;
  animation: zoomInRoom 2s ease;
}

@keyframes zoomInRoom {
  0% {
    background-size: var(--bg-size);
  }
  100% {
    background-size: calc(1.1 * var(--bg-size));
  }
}

.roomSection.clicked {
  /* height: auto; */
  /* background-size: calc(1.2 * var(--bg-size)); */
  background-position-x: left;
  background-position-y: top;
}

.tvDiv {
  position: absolute;
  width: 22%;
  top: 20%;
  left: 3%;
  animation: zoomInTv 2s ease;
  transition: all 1s ease-in-out;
}

.tvDiv img {
  width: 100%;
  transition: all 1s ease-in-out;
}

@keyframes zoomInTv {
  0% {
    width: 20%;
  }
  100% {
    width: 22%;
  }
}

.clicked .tvDiv {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  z-index: 100;
}
.clicked .tvDiv img {
  transform: rotate(-90deg) scale(2);
}

.roomSection form {
  display: none;
}
.clicked form {
  position: absolute;
  display: flex;
  width: 30vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: fadeIn 1s ease-in-out;
  z-index: 150;
}

.clicked form div {
  width: 100%;
}
.clicked form label {
  font-size: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.clicked ul {
  position: absolute;
  display: flex;
  width: 30vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: fadeIn 1s ease-in-out;
  z-index: 150;
  font-size: 150%;
}

@media screen and (max-width: 767px) {
  .roomSection {
    background-image: url('../../assets/callerdao/dao_tv-wall.png');
    position: relative;
  }
  .tvDiv {
    position: relative;
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    animation: none;
    left: 0;
  }
  .onClickDiv {
    display: none;
  }
  .roomSection form {
    display: flex;
    flex-direction: column;
    position: absolute;
  }
  .clicked form {
    width: 60vw;
  }
  .clicked form label {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  body {
    overflow: auto;
  }
  .roomSection {
    background-image: url('../../assets/callerdao/dao_tv-wall.png');
    position: relative;
  }
  .onClickDiv {
    display: none;
  }
  .tvDiv {
    position: relative;
    width: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    animation: none;
    left: 0;
  }
  .roomSection form {
    display: flex;
    flex-direction: column;
    position: absolute;
  }
}
