.proposals {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}

.proposal {
  width: 100%;
  padding: 30px;
  margin-bottom: 30px;
  border: 2px solid;
  background: white;
  display: flex;
  flex-direction: column;
}

.votingBox {
  display: flex;
  align-self: end;
}

.votingBox > div {
  width: 100px;
}

.votingBox button {
  width: 50px;
  height: 50px;
}

.forClicked {
  background: green;
}
.againstClicked {
  background: red;
}
