* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-image: url('../../assets/website\ caller\ inu/pc/temp_bg.jpg');
  /* background-image: url('../../assets/Caller/web_all.png'); */
  background-size: cover;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content section {
  max-width: min(1000px, 90vw);
}

.content section.roadmap {
  max-width: 1500px;
}

/* h2 {
  font-family: headersFont;
  text-align: center;
  color: #ffc012;
  font-size: 6rem;
  text-shadow: 2px 3px 0px #000000;
  position: absolute;
  top: -70px;
  left: 45%;
} */

.introductionContainer {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 200px;
  padding-bottom: 50px;
}

.introduction {
  margin-bottom: 1rem;
}

/* .utilities {
  margin-bottom: 50px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 50px;
  padding-bottom: 50px;
} */

.utilities,
.utilitiesMobile {
  position: relative;
}

.utilitiesImage {
  width: 100%;
}

.botButton {
  position: absolute;
  top: 20%;
  width: 90%;
}

.utilitiesMobile .botButton {
  width: 95%;
}

.utilitiesMobile .botButton,
.utilitiesMobile .botButtonShadow,
.utilitiesMobile .botButtonShadowHover {
  top: 17%;
}

.botButtonShadow,
.botButtonShadowHover {
  position: absolute;
  top: 20%;
  left: 40%;
  width: 50%;

  opacity: 0;
}

.botButtonShadowHover {
  z-index: 100;
  transform-origin: top;
  transform: scale(0.8);
}

.botButtonShadowHover:hover + .utilitiesBackground .botButtonShadow {
  opacity: 1;
}

.taxesAndToken,
.roadmap,
.team,
.supportedBy {
  width: 90%;
  margin-bottom: 100px;
  margin-left: 5%;
  margin-right: 5%;
}

.gallery {
  display: flex;
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  gap: 3%;
  padding-top: 3%;
  padding-bottom: 3%;
  align-items: flex-start;
}

.taxesAndToken .header,
.taxesAndTokenMobile .header {
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 50px;
  margin-top: 80px;
}

.roadmap .header,
.team .header {
  width: 25%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 50px;
}
.roadmapMobile .header {
  width: 45%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 50px;
}

.header > img {
  max-width: 100%;
}

.galleryLeft {
  width: 37%;
}
.galleryRight {
  width: 60%;
}

.galleryLeft img {
  width: 100%;
  height: auto;
}

.galleryRight img {
  width: 100%;
  height: auto;
}

.teamMember div {
  width: 30%;
  margin-bottom: 30px;
}

.teamMember img {
  width: 100%;
  /* max-width: 450px;
  max-height: 350px; */
}

.martin {
  transform: scale(0.8);
  transform-origin: top;
}

.supportedBy .header {
  width: 100%;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 100px;
}

.supportedBy .header img {
  height: 70px;
  width: auto;
}

.supportedByLogos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 80px;
}

.contactUs {
  width: 100%;
  padding-top: 150px;
  margin-bottom: 200px;
  position: relative;
}

.contactUs .header {
  width: 30%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 50px;
}

.phoneImage {
  width: 90%;
  position: absolute;
  top: 250px;
  margin-left: 10%;
}

.introImageMobile,
.utilitiesMobile,
.taxesAndTokenMobile,
.roadmapMobile,
.galleryMobile,
.contactUsMobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .introImage,
  .utilities,
  .taxesAndToken,
  .roadmap,
  .gallery,
  .contactUs {
    display: none;
  }
  .introImageMobile,
  .utilitiesMobile,
  .contactUsMobile {
    display: block;
  }
  .introductionContainer {
    padding-bottom: 10px;
  }

  .issueDiv {
    width: 20px;
    height: 34px;
  }

  .utilities {
    padding-top: 10px;
    padding-bottom: 0;
  }

  .taxesAndTokenMobile,
  .roadmapMobile,
  .galleryMobile {
    display: block;
    width: 90%;
    margin-bottom: 50px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .taxesAndTokenMobile .header {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
  }
  .galleryMobile div,
  .galleryDescription > img {
    width: 100%;
  }
  .galleryDescription div {
    height: 150px;
    position: relative;
  }
  .lips {
    position: absolute;
    width: 70%;
    bottom: 50%;
    top: 50%;
    transform: translateY(-50%);
  }
  .phoneDiv {
    height: 50px;
    position: relative;
  }
  .phone {
    position: absolute;
    width: 80%;
    right: 0;
    bottom: 50%;
    top: 50%;
    transform: translateY(-50%);
  }

  .team {
    padding-top: 200px;
  }
  .team .header {
    width: 50%;
  }

  .supportedBy {
    margin-top: 100px;
  }
  .supportedBy .header {
    width: 70%;
    padding-bottom: 50px;
  }
  .supportedBy h4 {
    font-size: 1.5rem;
  }

  .contactUsMobile,
  .contactUsMobile img {
    width: 100%;
  }
}
