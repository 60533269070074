.navigation {
  height: 200px;
  max-width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 999;
}

.navigationMenu {
  display: flex;
  align-items: center;
  width: 100%;
}

.brandLogo {
  height: 150px;
}

.links {
  /* margin-right: auto; */
  padding-left: 3%;
  width: 100%;
  word-break: break-word;
}

.links a {
  font-family: headersFont;
  color: white !important;
  -webkit-text-stroke: 1px red;
  font-size: 1.85rem;
  padding: 0em 0.5em 0em 0.5em;
  display: inline-block;
}

.links a:hover {
  background-image: url('../../assets/website\ caller\ inu/pc/heather_menu_pop_ef.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  text-decoration: none;
}

.socialMedia {
  font-family: headersFont;
  display: flex;
  align-items: center;
}

.socialMedia img {
  height: 30px;
  margin-right: 0.5em;
}

.socialMedia button {
  background-color: #d2d1db;
  border-radius: 20px;
  font-size: 1.5em;
  padding: 0.1em 1em;
  white-space: nowrap;
}
.socialMedia button:hover {
  cursor: url('../../assets/Caller/cursor\ copy.png'), auto;
}

.hamburger {
  background: none;
  cursor: pointer;
  margin: 0;
  display: none;
}

.hamburger svg {
  color: #d2d1db;
  height: 40px;
  width: 40px;
}

@media screen and (max-width: 550px) {
  .navigation {
    height: 150px;
  }
  .brandLogo {
    max-height: 80px;
  }
  .socialMedia img {
    height: 20px;
    margin-right: 0.3rem;
  }
  .socialMedia button {
    font-size: 0.8rem;
    padding: 0.1rem 0.3rem;
    margin: 0;
  }
}
@media screen and (min-width: 551px) and (max-width: 768px) {
  .brandLogo {
    height: 150px;
  }
  .socialMedia img {
    height: 25px;
    margin-right: 0.5rem;
  }
  .socialMedia button {
    border-radius: 20px;
    font-size: 1.3em;
    padding: 0.1em 0.5em;
    margin: 0;
  }
}

.stakingPopup {
  height: 300px;
  width: 600px;
  max-width: 600px;
}

.daoPopup {
  width: 1000px;
  max-width: 1000px;
  background: transparent;
}
.daoPopup img {
  object-fit: scale-down;
  background: transparent;
  overflow: hidden;
}

@media screen and (max-width: 1099px) {
  .hamburger {
    display: block;
  }
  .stakingPopup {
    width: unset;
    max-width: unset;
  }

  .daoPopup {
    width: 100%;
    max-width: 100%;
    margin: revert;
  }

  .navigationMenu {
    padding-top: 1%;
    padding-bottom: 1%;
    position: absolute;
    top: 120px;
    left: 0;
    flex-direction: column;
    background-color: black;
    display: none;
  }
  .links {
    margin-right: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .socialMedia {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }

  .brandLogo.expanded,
  .socialMedia.expanded {
    display: none;
  }

  .navigationMenu.expanded {
    display: block;
    height: 100vh;
  }
  .hamburger svg.expanded {
    color: #00aeef;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1400px) {
  .brandLogo {
    height: 120px;
  }
  .links a {
    font-size: 1.6rem;
  }
  .socialMedia img {
    height: 25px;
    margin-right: 0.3rem;
  }
  .socialMedia button {
    font-size: 1rem;
    padding: 0.1rem 0.3rem;
    margin: 0;
  }
}

:global .modal {
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
}

:global .modal-dialog {
  background: transparent;
  overflow: hidden;
}
/* :global .modal-dialog {
  width: 60%;
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  :global .modal-dialog {
    max-width: 100%;
  }
} */

:global .modal-content {
  background: transparent;
  overflow: hidden;
}

.stakingPopup > .modal-content {
  height: 100%;
}
