.periodDiv {
  margin: 5% 0;
  padding: 3% 5%;
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  border-radius: 10px;
  border: 5px solid black;
}

.periodDiv.daopage {
  z-index: 1;
  position: absolute;
  top: 0;
  width: 270%;
  transition: all 1s ease-in-out;
  opacity: 1;
  animation: fade-in 1s ease;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.periodDiv h4 {
  margin: 0;
}
