.fullScreenAnimation {
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  overflow: hidden;
  backface-visibility: hidden;
}

@media screen and (max-width: 800px) {
  .fullScreenAnimation{
    display: none;
  }
}