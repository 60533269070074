@font-face {
  font-family: headersFont;
  src: url('./assets/website\ caller\ inu/pc/f_BADABB_.TTF');
}

@font-face {
  font-family: brandLogoFont;
  src: url('./assets/website\ caller\ inu/pc/f_agentorange.ttf');
}

@font-face {
  font-family: pFont;
  src: url('./assets/website\ caller\ inu/pc/f_digistrip_0.ttf');
}
@font-face {
  font-family: pFontItalic;
  src: url('./assets/website\ caller\ inu/pc/f_digistrip_i.ttf');
}
@font-face {
  font-family: pFontBoldItalic;
  src: url('./assets/website\ caller\ inu/pc/f_digistrip.ttf');
}
@font-face {
  font-family: bernierRegular;
  src: url('./assets/fonts/BERNIERRegular-Regular.ttf');
}
