.navbar {
  width: 100%;
  background-color: #2f3e46;
}

.navbar img {
  padding-left: 2em;
}

.content {
  background-image: url('../../assets/staking/Caller-Farm-bg-1920x1280.png');
  background-size: 100% 110%;
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  background-position-y: bottom;
  position: relative;
}

.stakeTitle {
  max-width: 530px;
  max-height: 200px;
  position: relative;
}

.stakeTitle > img {
  max-width: 100%;
}
.toBeStakedBox,
.toBeUnstakedBox {
  width: 48%;
  height: 30%;
  position: absolute;
  font-family: pFont;
  font-size: 2rem;
  text-align: center;
}

.toBeStakedBox {
  bottom: 10%;
}

.toBeUnstakedBox {
  bottom: 10%;
  right: 0;
}

.stakingButtons {
  display: flex;
  height: 200px;
  width: 100%;
  max-width: 700px;
}

.leftButtonHolder,
.rightButtonHolder {
  width: 50%;
  height: 100%;
  position: relative;
}

.leftButtonHolder > video,
.rightButtonHolder > video {
  max-width: 100%;
  position: absolute;
  bottom: 0;
}

.stakingModal {
  width: min(80%, 1000px);
  border-image-slice: 52 64 58 64;
  border-image-width: 20px 20px 20px 20px;
  border-image-outset: 0px 0px 0px 0px;
  border-image-repeat: stretch stretch;
  border-image-source: url('../../assets/staking/index.png');
  border-style: solid;
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 38px;
  border-radius: 15px;
}

.stakingModal button {
  padding: 10px 15px;
  margin-left: auto;
  border: none;
  background: none;
}

.modalContent {
  align-self: center;
}

.modalContent p {
  font-family: pFont;
  font-size: 20px;
  margin: 20px;
  text-align: center;
  text-align-last: center;
}

.modalClose {
  display: none;
  z-index: 1;
}

.spinner {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 20px;
  width: 50px;
  height: 50px;
  border: 5px solid;
  border-color: #000 transparent #000 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 700px) {
  .content {
    background-image: url('../../assets/staking/Caller-Farm-bg-vertical.png');
  }
  .stakeTitle {
    padding: 0 20px;
  }
  .stakingButtons {
    height: 23%;
  }
}
@media screen and (max-width: 600px) {
  .stakingButtons {
    height: 20%;
  }
}
@media screen and (max-width: 500px) {
  .stakingButtons {
    height: 15%;
  }
}
