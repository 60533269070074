.imagesWrapper {
  width: 690px;
  height: 690px;
  position: relative;
  margin: 1vh auto 15vh;
  border-radius: 20px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .imagesWrapper {
    width: 400px;
    height: 400px;
  }
}
@media screen and (max-width: 500px) {
  .imagesWrapper {
    width: 350px;
    height: 350px;
  }
}

.title {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
}

.titleImage {
  width: 250px;
}

.slider {
  width: 100%;
}

.beforeImage,
.afterImage {
  width: auto;
  height: 70vh;
  border-radius: 20px;
}

.beforeImage {
  height: 100%;
}

.beforeImageBackground {
  max-height: 100%;
}

.beforeImageNft {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
}

.afterImage {
  z-index: 2;
  height: 100%;
  position: relative;
}

.compareOverlay {
  position: absolute;
  overflow: hidden;
  height: 100%;
  top: 0;
  border-radius: 20px;
}

.compareRange {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  right: 0;
  left: 0;
  height: 50px;
  cursor: ew-resize;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
}

.handleWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.handle {
  width: 100px;
  z-index: 6;
}

.handleLine {
  content: '';
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  z-index: 4;
  pointer-events: none;
  user-select: none;
}
