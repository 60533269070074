.footer {
  height: 200px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1% 5% 1% 5%;
}

.brandLogo {
  height: 150px;
}

.socialMedia img {
  height: 40px;
  margin-right: 0.8em;
}

@media screen and (max-width: 610px) {
  .footer {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .brandLogo {
    height: 100px;
  }
  .socialMedia img {
    height: 25px;
    margin-right: 0.5rem;
  }
}
